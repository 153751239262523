<template>
  <div
    class="cancel-confirmed tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-screen tw-p-4 tw-bg-green-light"
  >
    <!-- Survey Icon -->
    <img
      src="@/assets/svgs/create-a-trip/survey-icon.svg"
      alt="Survey Icon"
      width="67"
      height="52"
      class="tw-mb-4"
    />

    <!-- Bummer! Text -->
    <p
      class="tw-font-figtree tw-text-2xl tw-font-semibold tw-leading-9 tw-mb-2"
    >
      Bummer!
    </p>

    <!-- Description Text -->
    <p
      class="tw-font-figtree tw-text-base tw-font-light tw-leading-6 tw-text-center tw-mb-8"
    >
      Your trip was canceled and the crew was notified.
    </p>

    <!-- Back to My Trips Link -->
    <router-link
      :to="{ name: 'TripsRoute' }"
      class="tw-font-figtree tw-text-lg tw-font-semibold tw-text-charcoal tw-underline tw-cursor-pointer"
    >
      Back to My Trips
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CancelConfirmed",
};
</script>

<style scoped>
.tw-font-figtree {
  font-family: "Figtree", sans-serif;
}
.tw-text-charcoal {
  color: #203848;
}
.tw-bg-green-light {
  background-color: #e6ffa1;
}
</style>
